import React from 'react';
import { Button, Icon } from '@nimbus-ds/components';
import { useHistory } from 'react-router-dom';
import { CogIcon } from '@nimbus-ds/icons';
import { t } from 'i18next';

const ConfigButton: React.FC = () => {
  const { push } = useHistory();
  const handleGoToConfigs = () => push('/configs');

  return (
    <Button onClick={handleGoToConfigs}>
      <Icon source={<CogIcon />} color="currentColor" />
      {t('general.config')}
    </Button>
  );
};

export default ConfigButton;
